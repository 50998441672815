import * as React from 'react'
import * as xlsx from 'xlsx'

const required = ['Make', 'Model', 'ID']
const parse = (data: string) => {
    const workbook = xlsx.read(data)
    const items = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
    const equipment = items.filter((item: any) => {
        return required.every((r) => item && item.hasOwnProperty(r))
    }).map((item: any) => ({
        description: item.Description,
        id: item.ID,
        make: item.Make,
        model: item.Model,
        tag: item.Tag
    }))
    // Currently only support 500 items at a time
    equipment.length = Math.min(items.length, 500)
    return equipment
}

const handleFile = (file) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (typeof reader.result !== 'string') {
                return
            }
            let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        }
        reader.onerror = err => reject(err)
    })
}

interface IProps {
    onLoaded: (data: any[]) => void
}
const Upload = ({ onLoaded }: IProps) => {
    const process = (file) => handleFile(file)
        .then(data => {
            const preview = parse(data)
            if (preview.length === 0) {
                alert('There were no usable rows found in this document')
            }
            onLoaded(preview)
        })
        .catch(err => console.error(err))
    return (<div className='file'>
        <label className="file-label">
            <input type='file'
                id='file'
                className='file-input'
                accept='.xlsx'
                onChange={e => process(e.target.files[0])} />
            <span className="file-cta">
                <span className="file-icon">
                    <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                    Upload Equipment <small style={{ paddingTop: 4, marginLeft: 10, fontSize: 11 }}>(.xlsx)</small>
                </span>
            </span>
        </label>
    </div>)
}

export default Upload
