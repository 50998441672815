import * as React from 'react'
import Box from 'react-bulma-components/lib/components/box'
import Notification from 'react-bulma-components/lib/components/notification'
import Button from 'react-bulma-components/lib/components/button'

const Instructions = () => (
    <div>
        <h4 style={{ marginTop: 0 }}>1. Get The Template</h4>
        <div>
            <Button color="link" style={{ marginRight: 20 }} onClick={(e) => window.open('/template.xlsx', '_blank')}>
                <i className="fas fa-file-excel"></i>Excel Template<small style={{ marginLeft: 10 }}>.xlsx</small>
            </Button>
            <Button color="success" onClick={(e) => window.open('https://docs.google.com/spreadsheets/d/1rc816yuke80UZ0rJvhP9d9ZXZwc4XezkIGq557T_WgU/edit?usp=sharing', '_blank')}>
                <i className="fab fa-google-drive"></i>Google Drive Template
            </Button>
        </div>
        <h4>2. Upload Filled Out Spreadsheet</h4>
        <ul>
            <li>Fill out the provided template (don't change the headers)</li>
            <li>Save/Export it as an .xlsx file</li>
            <li>Click the "Upload Equipment" button and select the file on your computer</li>
            <li><strong>A maximum of 500 equipment can be imported at a time</strong></li>
        </ul>
        <h4>3. Review and Import</h4>
        <ul>
            <li>After loading your spreadsheet file you will be given a preview of the equipment</li>
            <li>Review the data presented</li>
            <li>Click the "Import" button to commit the data to your account</li>
        </ul>
    </div>
)

export default Instructions
