import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import env from './env'

firebase.initializeApp(env.firebase)

export const db = firebase.firestore()

db.settings({
    timestampsInSnapshots: true
})

export default firebase
