import * as React from 'react';
import { Formik, Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import Container from 'react-bulma-components/lib/components/container';
import Notification from 'react-bulma-components/lib/components/notification';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Subscribe from './Subscribe';
import axios from 'axios';
import env from '../env';
import * as BulmaForm from 'react-bulma-components/lib/components/form';
import AppPreview from './AppPreview';
import '../sass/dashboard.sass';
const barsImg = require("./../assets/img/bars.png");

interface IState {
    sent: boolean,
    loading: boolean,
    error: string,
    fetchInProgress: boolean,
    subscriptionUpdateStatus: string,
    submitInProgress: boolean,
    setupLoading: boolean
}

interface IProps {
    refreshSubscription: () => void
    idToken: string
    subscription: {
        active: boolean
        trial: boolean
        tier: string
        recurly_uuid: string
    }
    user: any
}

class Dashboard extends React.Component<IProps, IState> {

    state = {
        user: null,
        sent: false,
        loading: true,
        error: '',
        fetchInProgress: false,
        subscriptionUpdateStatus: '',
        submitInProgress: false,
        setupLoading: false
    }

    recurlyRedirect() {
        this.setState({ fetchInProgress: true });
        axios.post(global.apiRoot + '/api/recurlyLink',
            { identityToken: this.props.idToken },
            {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                console.log(response);
                window.location = response.data.url;
            }).catch(error => {
                // Error
                this.setState({ fetchInProgress: false });
                console.log("\nfetch ERROR: \n", error,
                    "\nerror.response\n", error.response);
            });

    }

    createMarkup() {
        return { __html: '<input type="text" class="recurly-hosted-field" data-recurly="first_name" placeholder="First Name"><input type="text" class="recurly-hosted-field" data-recurly="last_name" placeholder="Last Name"><br><input type="text" class="recurly-hosted-field" data-recurly="address1" placeholder="Address"><br><input type="text" class="recurly-hosted-field" data-recurly="city" placeholder="City"><input type="text" class="recurly-hosted-field" data-recurly="state" placeholder="State"><br><input type="text" class="recurly-hosted-field" data-recurly="postal_code" placeholder="Postal Code"><input type="text" class="recurly-hosted-field" data-recurly="country" placeholder="Country" value="US"><div data-recurly="card"></div><input type="hidden" name="recurly-token" data-recurly="token"><button>submit</button>' };
    };

    render() {
        const { sent } = this.state
        var panel = 'dashboard';
        return (
            <div>
                {this.props.user &&
                    <Container className="dashboard">
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                {(typeof this.props.subscription.tier === 'undefined' || (typeof this.props.subscription.trial !== 'undefined' && this.props.subscription.trial)) &&
                                    <Subscribe refresh={this.props.refreshSubscription} user={this.props.user} subscription={this.props.subscription} />
                                }
                                {(typeof this.props.subscription.tier !== 'undefined' && ((typeof this.props.subscription.trial !== 'undefined' && !this.props.subscription.trial) || (typeof this.props.subscription.trial === 'undefined'))) &&
                                    <LoadingOverlay active={this.state.fetchInProgress} spinner text='Loading...'>
                                        <div className="subscribed">
                                            <h3>Subscription</h3>
                                            {this.props.subscription.active &&
                                                <p>Your subscription is currently active.</p>
                                            }
                                            {!this.props.subscription.active &&
                                                <p>Your subscription is currently inactive.</p>
                                            }
                                            <p>
                                                Need to update your credit card information, cancel your account, or see billing history?
                                            </p>
                                            {!this.props.subscription.recurly_uuid != null &&
                                            <p>
                                                <Button color="primary" onClick={this.recurlyRedirect.bind(this)}>Manage my Subscription</Button>
                                            </p>
                                            }
                                        </div>
                                    </LoadingOverlay>
                                }
                            </div>
                            <div className="col-md-3 screenshot">
                                <AppPreview showDownload={this.props.subscription.active} />
                            </div>
                        </div>
                    </Container>
                }
            </div>
        )
    }

}

export default Dashboard
