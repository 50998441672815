import * as React from 'react'
import Navbar from 'react-bulma-components/lib/components/navbar';
import firebase from '../firebase';
import { NavLink, Link } from "react-router-dom";
import '../sass/header.sass'

const logo = require("./../assets/img/logo.png")

const Header = ({ user, subscription }) => {
    return (<div className="header">
        <div className="navbar navbar-dark navbar-expand-md app-bar col-md-8">
            <Link to="/" className="brand-link navbar-brand">
                <Navbar.Brand>
                    <img
                        src={logo}
                        alt="Equappment"
                        height="48"
                    />
                    <h1>Equappment</h1>
                </Navbar.Brand>
            </Link>

            {user && <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav">
                <span className="navbar-toggler-icon"></span>
            </button>}

            <ul className="navbar-nav navbar-collapse collapse mr-auto" id="mainNav">
                {user && <li className="nav-item">
                    <NavLink activeClassName="active" to="/" exact>
                        Subscription
                    </NavLink>
                </li>}
                {user && <li className="nav-item">
                    {subscription.active && <NavLink activeClassName="active" to="/importer">
                        Importer
                    </NavLink>}
                </li>}
                {user && <li className="nav-item">
                    <a href="http://help.equappment.com/support/home" target="_blank">
                        Help
                    </a>
                </li>}
                {user && <li className="nav-item ml-auto">
                    <Link to="/"
                        onClick={(e) => firebase.auth().signOut()}
                        href="#">
                        Sign Out
                    </Link>
                </li>}
            </ul>

        </div>
    </div>)
}

export default Header
