import * as React from 'react'

const previewImg = require("./../assets/img/equappment-screenshot.png")
const downloadImg = require("./../assets/img/app-store.svg")

const AppPreview = ({ showDownload = false }) => {
    return (
        <div className="app-preview">
            {showDownload && <div className="download">
                <h1>Download the App Now</h1>
                <a href="https://itunes.apple.com/us/app/equappment/id1454739075?mt=8" target="_blank"><img src={downloadImg} width="200" height="60" /></a>
            </div>}
            <img src={previewImg} width="428" style={{maxWidth: '90%', marginTop: '1em'}} />
        </div>
    )
}

export default AppPreview
