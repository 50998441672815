import * as React from 'react'
import Table from 'react-bulma-components/lib/components/table'
import Box from 'react-bulma-components/lib/components/box'

const PreviewTable = ({ data = [] }) => {
    return <div>
        <Box>
            <h3 style={{ marginTop: 0 }}>Equipment Import Preview</h3>
            <p>
                Review the equipment below and then click the yellow "import" button
            </p>
            <Table>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Description</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Tag</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((d, index) => (
                        <tr key={index}>
                            <td>
                                {d.id}
                            </td>
                            <td>
                                {d.description}
                            </td>
                            <td>
                                {d.make}
                            </td>
                            <td>
                                {d.model}
                            </td>
                            <td>
                                {d.tag}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Box>
    </div>
}

export default PreviewTable
