const prodEnv = {
    env: 'production',
    recurly: {
        publicKey: 'ewr1-5MgZwVgX1U6Ie6tgMa89ui'
    },
    firebase: {
        apiKey: "AIzaSyDirQlmJUqiKsZOof90_emuTqqnIifYtJE",
        authDomain: "equappment.firebaseapp.com",
        databaseURL: "https://equappment.firebaseio.com",
        projectId: "equappment",
        storageBucket: "equappment.appspot.com",
        messagingSenderId: "953408829774"
    },
    magicLink: {
        url: 'https://importer.equappment.com',
        handleCodeInApp: true,
        dynamicLinkDomain: 'equappmentapp.page.link'
    }
}

const stageEnv = {
    env: 'staging',
    recurly: {
        publicKey: 'ewr1-YJibAYzzEHt5PlHYMXJQ4P'
    },
    firebase: {
        apiKey: "AIzaSyCXehjdXL754XXsDJLhHzhUNZsz277awT4",
        authDomain: "equappment-staging.firebaseapp.com",
        databaseURL: "https://equappment-staging.firebaseio.com",
        projectId: "equappment-staging",
        storageBucket: "equappment-staging.appspot.com",
        messagingSenderId: "1036026962882"
    },
    magicLink: {
        url: 'https://equappment-staging.firebaseapp.com',
        handleCodeInApp: true,
        dynamicLinkDomain: 'equappmentstageweb.page.link'
    }
}

const devEnv = {
    env: 'development',
    recurly: {
        publicKey: 'ewr1-YJibAYzzEHt5PlHYMXJQ4P'
    },
    firebase: {
        apiKey: "AIzaSyAvgsn09fRelrngxs8IXpmCHMdGRxsYcDc",
        authDomain: "equappment-development.firebaseapp.com",
        databaseURL: "https://equappment-development.firebaseio.com",
        projectId: "equappment-development",
        storageBucket: "equappment-development.appspot.com",
        messagingSenderId: "812809187356",
    },
    magicLink: {
        url: 'https://equappment-development.firebaseapp.com',
        handleCodeInApp: true,
        dynamicLinkDomain: 'equappmentdev.page.link'
    }
}

const localEnv = {
    env: 'local',
    magicLinkURL: 'http://localhost:8081',
}

let env = prodEnv
if (__ENV__ === 'staging') {
    env = {
        ...prodEnv,
        ...stageEnv
    }
} else if (__ENV__ === 'development') {
    env = {
        ...prodEnv,
        ...devEnv
    }
} else if (__ENV__ === 'local') {
    env = {
        ...prodEnv,
        // ...stageEnv,
        ...devEnv,
        ...localEnv
    }
}

console.log(env, env)

export default env
