import * as React from "react";
import Box from 'react-bulma-components/lib/components/box';
import firebase, { db } from '../firebase';
import "./../sass/app.sass";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Container from 'react-bulma-components/lib/components/container';
import Dashboard from './Dashboard';
import Importer from './Importer';
import Subscribe from './Subscribe';
import axios from 'axios';
import Header from './Header';
import Auth from './Auth';

interface IState {
    user: any
    idToken: string
    subscription: {
        active: boolean,
        authorized: boolean
    },
    userAuthInProgress: boolean,
    authorized: boolean
}

class App extends React.Component<any, IState> {

    state = {
        user: null,
        idToken: null,
        subscription: null,
        userAuthInProgress: false,
        authorized: true
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            this.setState({ userAuthInProgress: true });
            console.log('auth state change', user)
            if (user) {
                console.log('got user', user)
                const docRef = db.collection('users').doc(user.uid)
                docRef.get().then((userDocument) => {
                    firebase.auth().currentUser.getIdToken().then((token) => {
                        axios.post(global.apiRoot + '/api/checkSubscription',
                            { identityToken: token },
                            {
                                headers: {
                                    'Accept': 'application/json',
                                }
                            }).then(response => {
                                console.log(response)
                                history.pushState({}, '', '/')
                                this.setState({ user, idToken: token, subscription: response.data, userAuthInProgress: false, authorized: response.data.authorized });
                            }).catch(error => {
                                // Error
                                this.setState({ userAuthInProgress: false });
                                firebase.auth().signOut()
                                console.log("Subscription status error: ", error);
                            });

                    }).catch((error) => { this.setState({ userAuthInProgress: false }); });

                })
            } else {
                console.log('failed login')
                // Unauthorized - Do Nothing
                this.setState({ userAuthInProgress: false, user: null });
            }
        })
    }

    refreshSubscription = async () => {
        firebase.auth().currentUser.getIdToken().then((token) => {
            axios.post(global.apiRoot + '/api/checkSubscription',
                { identityToken: token },
                {
                    headers: {
                        'Accept': 'application/json',
                    }
                }).then(response => {
                    console.log(response)
                    history.pushState({}, '', '/')
                    this.setState({ subscription: response.data, authorized: response.data.authorized });
                }).catch(error => {
                    // Error
                    this.setState({ userAuthInProgress: false });
                    firebase.auth().signOut()
                    console.log("Subscription status error: ", error);
                });

        })
    }

    render() {
        const { user } = this.state
        console.log(this.state)
        return (
            <React.Fragment>
                <Router>
                    <Header user={user} subscription={this.state.subscription} />
                    <div className="content-container container">
                        {!user && <Auth loading={this.state.userAuthInProgress} />}
                        {this.state.authorized &&
                            <Container>
                                <Route exact path="/" render={() => <Dashboard refreshSubscription={this.refreshSubscription} user={this.state.user} idToken={this.state.idToken} subscription={this.state.subscription} />}></Route>
                                <Route path="/importer" render={() => <Importer user={this.state.user} subscription={this.state.subscription} />}></Route>
                            </Container>
                        }
                        {!this.state.authorized &&
                            <Container>
                                <Box>
                                    Sorry, you're not authorized to manage this company.
                            </Box>
                            </Container>
                        }
                    </div>
                </Router>
            </React.Fragment>
        )
    }
}

export default App
