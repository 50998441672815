import * as React from 'react'
import Login from './Login';
import Register from './Register';
import Container from 'react-bulma-components/lib/components/container';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import AppPreview from './AppPreview'
import '../sass/auth.sass'

const Auth = (props) => {
    return (<div className="auth row justify-content-center">
        <div className="content col-sm-4">
            <Router>
                <Route exact path="/" render={() => <Login {...props} />}></Route>
                <Route path="/sign-up" render={() => <Register />}></Route>
            </Router>
        </div>
        <div className="screenshot col-sm-4" style={{ textAlign: 'center' }}>
            <AppPreview />
        </div>
    </div>)
}
export default Auth
