import * as React from "react";
import {render} from "react-dom";
import {AppContainer} from "react-hot-loader";
import App from "./components/App";

const rootEl = document.getElementById("root");

if (__ENV__ === 'local') { global.apiRoot = 'http://localhost:5000/equappment-development/us-central1'; }
else if (__ENV__ === 'staging') { global.apiRoot = 'https://us-central1-equappment-staging.cloudfunctions.net'; }
else if (__ENV__ === 'production') { global.apiRoot = 'https://us-central1-equappment.cloudfunctions.net'; }
else { global.apiRoot = 'https://us-central1-equappment-development.cloudfunctions.net'; }

render(
    <AppContainer>
        <App/>
    </AppContainer>,
    rootEl
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NewApp = require("./components/App").default;

        render(
            <AppContainer>
                <NewApp/>
            </AppContainer>,
            rootEl
        );
    });
}
