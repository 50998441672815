import * as React from 'react'
import Notification from 'react-bulma-components/lib/components/notification'

const Success = ({ numItems = 0, numDuplicate = 0 }) => (
    <Notification color="success">
        <h3>Equipment Import Successful</h3>
        <p>{numItems} pieces of equipment have been imported to your account.</p>
        {numDuplicate > 0 && <p>{numDuplicate} items were detected as duplicates (based on ID) and not imported.</p>}
        <p>Open the Equappment app on your device to view the imported equipment.</p>
    </Notification>
)

export default Success
